import React from 'react'
import Teamimage1 from '../../img/Teamimage1.jpg'
import Teamimage2 from '../../img/Teamimage2.jpg'
import Teamimage3 from '../../img/Teamimage3.jpg'
import Teamimage4 from '../../img/Teamimage4.jpg'
import './OurTeam.css'



const OurTeam = () => {
    return (
        <div><div class="container-fluid team py-5">
            <div class="container py-5">
                <div class="section-title mb-5 wow fadeInUp" data-wow-delay="0.1s">
                    <div class="sub-style">
                        <h4 class="sub-title px-3 mb-0">Meet our team</h4>
                    </div>
                    <h1 className="display-3 mb-4" style={{ fontWeight: "400" }}>
  Corpwings IT Solution Team Members
</h1>

                    <p class="mb-0" style={{color: '#8d8d8d', fontSize:'18px'}}>Our team of seasoned designers and developers meticulously curate every aspect of your website, ensuring seamless functionality, stunning aesthetics, and user-friendly navigation. </p>
                </div>
                <div class="row g-4 justify-content-center">
                    <div class="col-md-6 col-lg-6 col-xl-3 wow fadeInUp" data-wow-delay="0.1s">
                        <div class="team-item rounded">
                            <div class="team-img rounded-top h-100">
                            <img src={Teamimage1} className="img-fluid rounded-top w-100" alt="" />    
                            </div>
                            <div class="team-content text-center border border-primary border-top-0 rounded-bottom p-4">
                                <h5>Rajesh Kumar N </h5>
                                <p class="mb-0">Business Development Manager</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6 col-lg-6 col-xl-3 wow fadeInUp" data-wow-delay="0.3s">
                        <div class="team-item rounded">
                            <div class="team-img rounded-top h-100">
                            <img src={Teamimage2} className="img-fluid rounded-top w-100" alt="" />   
                            </div>
                            <div class="team-content text-center border border-primary border-top-0 rounded-bottom p-4">
                                <h5>Barath M</h5><br></br>
                                    <p class="mb-0">Managing Director</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6 col-lg-6 col-xl-3 wow fadeInUp" data-wow-delay="0.5s">
                        <div class="team-item rounded">
                            <div class="team-img rounded-top h-100">
                            <img src={Teamimage3} className="img-fluid rounded-top w-100" alt="" />
                            </div>
                            <div class="team-content text-center border border-primary border-top-0 rounded-bottom p-4">
                                <h5>Karthik Raja</h5><br></br>
                                    <p class="mb-0">Marketing Manager</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6 col-lg-6 col-xl-3 wow fadeInUp" data-wow-delay="0.7s">
                        <div class="team-item rounded">
                            <div class="team-img rounded-top h-100">
                            <img src={Teamimage4} className="img-fluid rounded-top w-100" alt="" />
                            </div>
                            <div class="team-content text-center border border-primary border-top-0 rounded-bottom p-4">
                                <h5>**************</h5><br></br>
                                    <p class="mb-0">General Manager</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div></div>
    )
}

export default OurTeam