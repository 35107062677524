import React, { useState } from 'react';
import CustomNavbar from './components/nav/Nav';
import HomeImgScroll from './components/homeImgScroll/HomeImgScroll';
import Services from './components/services/Services';
import About from './components/about/About';
import ChooseSection from './components/chooseSection/ChooseSection';
import Appointment from './components/appointment/Appointment';
import OurTeam from './components/ourTeam/OurTeam';
import ClientProjects from './components/clientProjects/ClientProjects';
import Blog from './components/blog/Blog';
import Contact from './components/contact/Contact';
import Footer from './components/footer/Footer';
import AdminLogin from './components/admin/AdminLogin';
import DownloadData from './components/admin/DownloadData';
import Intenship from './components/intenship/Intenship';

const App = () => {
  const [isAdmin, setIsAdmin] = useState(false);
  const [showAdminLogin, setShowAdminLogin] = useState(false);

  const handleLoginSuccess = () => {
    setIsAdmin(true);
    setShowAdminLogin(false); // Hide the login form after successful login
  };

  const handleAdminClick = () => {
    setShowAdminLogin(true); // Show the login form when the admin button is clicked
  };

  const handleCloseLogin = () => {
    setShowAdminLogin(false); // Hide the login form when cancel is clicked
  };

  return (
    <div>
      <CustomNavbar onAdminClick={handleAdminClick} />
      <div id="home"><HomeImgScroll /></div>
      <div id="services"><Services /></div>
      <div id="intenship"><Intenship /></div>
      <div id="about"><About /></div>
      <div id="chooseSection"><ChooseSection /></div>
      <div id="appointment"><Appointment /></div>
      <div id="team"><OurTeam /></div>
      <div id="clientProjects"><ClientProjects /></div>
      <div id="blog"><Blog /></div>
      <div id="contact"><Contact /></div>
      <div id="footer"><Footer /></div>
      
      {/* Conditionally Render Admin Login as an Overlay */}
      {showAdminLogin && !isAdmin && (
        <AdminLogin onLoginSuccess={handleLoginSuccess} onClose={handleCloseLogin} />
      )}

      {/* Admin Panel - Render only if authenticated as admin */}
      {isAdmin && (
        <DownloadData />
      )}
    </div>
  );
};

export default App;
