import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const TestimonialCarousel = () => {
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: true, // Use true/false to enable/disable arrows
        autoplay: true,
        autoplaySpeed: 3000, // Adjust autoplay speed as needed
    };

    return (
        <div className="container-fluid testimonial py-5 wow zoomInDown" data-wow-delay="0.1s">
            <div className="container py-5">
                <div className="section-title mb-5">
                    <div className="sub-style">
                        <h4 className="sub-title text-white-50 px-3 mb-0" style={{ marginBottom: '-70px' }}>
                            OUR CLIENTS
                        </h4>
                    </div>
                    <h1 className="display-3 mb-4">Client Project</h1>
                </div>
                <Slider {...settings} className="testimonial-carousel">
                    {/* <div className="testimonial-item">
                        <div className="testimonial-inner p-5">
                            <div className="testimonial-inner-img mb-4">
                                <h1 className="mb-2" style={{ color: 'white' }}>SEEWE<br />ADS & SERVICES</h1>
                            </div>
                            <div className="text-center">
                                <h5 className="mb-2" style={{ color: 'white' }}>
                                    At SeeWe, we're dedicated to helping businesses thrive in the digital world by providing comprehensive advertising solutions.
                                </h5>
                            </div>
                        </div>
                    </div> */}
                    <div className="testimonial-item">
                        <div className="testimonial-inner p-5">
                            <div className="testimonial-inner-img mb-4">
                                <h1 className="mb-2" style={{ color: 'white' }}>JCI INDIA</h1>
                            </div>
                            <div className="text-center">
                                <h5 className="mb-2" style={{ color: 'white' }}>
                                    Junior Chamber International (JCI) is a worldwide federation of young leaders.
                                </h5>
                            </div>
                        </div>
                    </div>
                    {/* <div class="testimonial-item">
                        <div class="testimonial-inner p-5">
                            <div class="testimonial-inner-img mb-4">
                               <h1 style={{color:"whitesmoke"}}>NEW VISION TRUST</h1>
                            </div>
                            <div class="text-center">
                                <h5 class="mb-2" style={{color:"white"}}>Our client support is designed with the sole intention of making your interactions easy and hassle-free.</h5>
                                
                            </div>
                        </div>
                    </div> */}
                    {/* Add other testimonial items similarly */}
                    {/* <div class="testimonial-item">
                        <div class="testimonial-inner p-5">
                            <div class="testimonial-inner-img mb-2">
                               <h1 class="mb-2" style={{color:"white"}}>SREE MAA APPLAM</h1>
                            </div>
                            <div class="text-center">
                                <h5 class="mb-2" style={{color:"white"}}>Sree Maa Appalam provides you the best range of sree maa appalam, other products with effective & timely delivery.</h5>
                                
                            </div>
                        </div>
                    </div> */}
                    <div class="testimonial-item">
                        <div class="testimonial-inner p-5">
                            <div class="testimonial-inner-img mb-4">
                                <h1 class="mb-2" style={{color:"white"}}>VISION FITNESS</h1>
                            </div>
                            <div class="text-center">
                                <h5 class="mb-2" style={{color:"white"}}>Today, Vision is committed to serving value-conscious customers with full-commercial strength.</h5>
                                
                            </div>
                        </div>
                    </div>
                    <div class="testimonial-item">
                        <div class="testimonial-inner p-5">
                            <div class="testimonial-inner-img mb-4">
                                <h1 class="mb-2" style={{color:"white"}}>FEMI</h1>
                            </div>
                            <div class="text-center">
                                <h5 class="mb-2" style={{color:"white"}}>If menstruation were an option, the very cycle of life would cease. It's important to recognize and respect women in this aspect.</h5>
                                
                            </div>
                        </div>
                    </div>
                    <div class="testimonial-item">
                        <div class="testimonial-inner p-5">
                            <div class="testimonial-inner-img mb-4">
                               <h1 class="mb-2" style={{color:"white"}}>ROYAL <br></br>SOLON</h1>
                            </div>
                            <div class="text-center">
                                <h5 class="mb-2" style={{color:"white"}}>A hair salon providing a range of services including haircuts,treatments, delivered by experienced professionals in a comfortable.</h5>
                                
                            </div>
                        </div>  
                    </div>
                    <div class="testimonial-item">
                        <div class="testimonial-inner p-5">
                            <div class="testimonial-inner-img mb-4">
                                <h1 class="mb-2" style={{color:"white"}}>FARM CAFE</h1>
                            </div>
                            <div class="text-center">
                                <h5 class="mb-2" style={{color:"white"}}>The restaurant is situated in the midst of organic farming and dense green landscaping.</h5>
                                
                            </div>
                        </div>
                    </div>
                    <div class="testimonial-item">
                        <div class="testimonial-inner p-5">
                            <div class="testimonial-inner-img mb-4">
                               <h1 class="mb-2" style={{color:"white"}}>TEA BENCH</h1>

                            </div>
                            <div class="text-center">
                                <h5 class="mb-2" style={{color:"white"}}>start your teavolution with us to bring an inspiring cup of rarest tea experiences.</h5>
                                
                            </div>
                        </div>
                    </div>
                </Slider>
            </div>
        </div>
    );
};

export default TestimonialCarousel;
