import '../../css/style.css';
import AppDeveloping from '../../img/AppDeveloping.jpg';
import WebDeveloping from '../../img/WebDeveloping.jpg';
import Nocode from '../../img/Nocode.jpg';
import Blog from '../../img/Blog.jpg';
import GraphicDesign from '../../img/GraphicDesign.jpg';
import BannerDesign from '../../img/BannerDesign.jpg';
import PosterDesign from '../../img/PosterDesign.jpg';
import VideoEditing from '../../img/VideoEditing.jpg';
import SEO from '../../img/SEEO.jpg';
import SocialMedia from '../../img/social media.jpg';
import ContentMarketing from '../../img/content marketing.jpg';
import WhatsApp from '../../img/whatsapp.jpg';
import SocialMediaHandling from '../../img/image-312x243 (1).jpg';
import InfluencerPromotion from '../../img/image-312x243 (2).jpg';
import PagePromotion from '../../img/image-312x243 (3).jpg';

const Services = () => {
    return (
        <div>
            <div className="container-fluid service py-5">
                <div className="container py-5">
                    <div className="section-title mb-5 wow fadeInUp" data-wow-delay="0.2s">
                        <div className="sub-style">
                            <h4 className="sub-title px-3 mb-0">What We Do</h4>
                        </div>
                        <h1 className="display-3 mb-4">OUR SERVICES</h1>
                        <h3 className="mb-0">DEVELOPING</h3>
                    </div>
                    <div className="row g-4 justify-content-center">
                        <div className="col-md-6 col-lg-4 col-xl-3 wow fadeInUp" data-wow-delay="0.1s">
                            <div className="service-item rounded">
                                <div className="service-img rounded-top">
                                    <img src={AppDeveloping} className="img-fluid rounded-top w-100" alt="" />
                                </div>
                                <div className="service-content rounded-bottom bg-light p-4">
                                    <div className="service-content-inner">
                                        <h5 className="mb-4">App Developing</h5>
                                        <p className="mb-4">Is a process of making software for smartphones, tablets and digital assistants, most commonly for the Android and iOS operating systems.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-4 col-xl-3 wow fadeInUp" data-wow-delay="0.3s">
                            <div className="service-item rounded">
                                <div className="service-img rounded-top">
                                    <img src={WebDeveloping} className="img-fluid rounded-top w-100" alt="img1" />
                                </div>
                                <div className="service-content rounded-bottom bg-light p-4">
                                    <div className="service-content-inner">
                                        <h5 className="mb-4">Web Developing</h5>
                                        <p className="mb-4">Website development, refers to the tasks associated with creating, building, and maintaining websites and web applications that run online.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-4 col-xl-3 wow fadeInUp" data-wow-delay="0.5s">
                            <div className="service-item rounded">
                                <div className="service-img rounded-top">
                                    <img src={Nocode} className="img-fluid rounded-top w-100" alt="" />
                                </div>
                                <div className="service-content rounded-bottom bg-light p-4">
                                    <div className="service-content-inner">
                                        <h5 className="mb-4">Nocode Developing</h5>
                                        <p className="mb-4">No-code development enables non-programmers to create business-critical apps using a graphical interface.It consists of drag-and-drop elements.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-4 col-xl-3 wow fadeInUp" data-wow-delay="0.7s">
                            <div className="service-item rounded">
                                <div className="service-img rounded-top">
                                    <img src={Blog} className="img-fluid rounded-top w-100" alt="" />
                                </div>
                                <div className="service-content rounded-bottom bg-light p-4">
                                    <div className="service-content-inner">
                                        <h5 className="mb-4">Blog</h5>
                                        <p className="mb-4">The blog description should contain relevant information about the topic being discussed in the post, such as key points, facts, and statistics.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <h3 style={{ textAlign: 'center', fontWeight: 500 }}>DESIGNINGS</h3>
                        <div className="col-md-6 col-lg-4 col-xl-3 wow fadeInUp" data-wow-delay="0.1s">
                            <div className="service-item rounded">
                                <div className="service-img rounded-top">
                                    <img src={GraphicDesign} className="img-fluid rounded-top w-100" alt="" />
                                </div>
                                <div className="service-content rounded-bottom bg-light p-4">
                                    <div className="service-content-inner">
                                        <h5 className="mb-4">GRAPHIC DESIGNING</h5>
                                        <p className="mb-4">Graphic designers create visual content for online and offline platforms like websites, print ads, logos, and videos, typography, graphic elements.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-4 col-xl-3 wow fadeInUp" data-wow-delay="0.3s">
                            <div className="service-item rounded">
                                <div className="service-img rounded-top">
                                    <img src={BannerDesign} className="img-fluid rounded-top w-100" alt="" />
                                </div>
                                <div className="service-content rounded-bottom bg-light p-4">
                                    <div className="service-content-inner">
                                        <h5 className="mb-4">BANNER DESIGNING</h5>
                                        <p className="mb-4">Banners are the creative rectangular ad that are shown along the top, side, or bottom of a website in hopes that it will drive traffic to the advertiser's site.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-4 col-xl-3 wow fadeInUp" data-wow-delay="0.5s">
                            <div className="service-item rounded">
                                <div className="service-img rounded-top">
                                    <img src={PosterDesign} className="img-fluid rounded-top w-100" alt="" />
                                </div>
                                <div className="service-content rounded-bottom bg-light p-4">
                                    <div className="service-content-inner">
                                        <h5 className="mb-4">POSTER DESIGNING</h5>
                                        <p className="mb-4">Is a type of graphic design. Poster artists utilize graphic design and usually have more flexibility and freedom beyond traditional marketing methods</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-4 col-xl-3 wow fadeInUp" data-wow-delay="0.7s">
                            <div className="service-item rounded">
                                <div className="service-img rounded-top">
                                    <img src={VideoEditing} className="img-fluid rounded-top w-100" alt="" />
                                </div>
                                <div className="service-content rounded-bottom bg-light p-4">
                                    <div className="service-content-inner">
                                        <h5 className="mb-4">VIDEO EDITING</h5>
                                        <p className="mb-4">They use for software applications and editing tools to merge content from multiple sources and add pictures, graphics, visual & sound effects.</p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* Marketing Section */}
                        <h3 className="mb-0" style={{ textAlign: 'center' }}>DIGITAL MARKETING</h3>
                        <div className="col-md-6 col-lg-12 col-xl-3 wow fadeInUp" data-wow-delay="0.1s">
                            <div className="service-item rounded">
                                <div className="service-img rounded-top">
                                    <img src={SEO} className="img-fluid rounded-top w-100" alt="" />
                                </div>
                                <div className="service-content rounded-bottom bg-light p-4">
                                    <div className="service-content-inner">
                                        <h5 className="mb-4">SEO</h5>
                                        <p className="mb-4">Search engines show this description in search results for your homepage if they don't find content more relevant to a visitor's search terms.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-4 col-xl-3 wow fadeInUp" data-wow-delay="0.3s">
                            <div className="service-item rounded">
                                <div className="service-img rounded-top">
                                    <img src={SocialMedia} className="img-fluid rounded-top w-100" alt="" />
                                </div>
                                <div className="service-content rounded-bottom bg-light p-4">
                                    <div className="service-content-inner">
                                        <h5 className="mb-4">SOCIAL MEDIA</h5>
                                        <p className="mb-4">It refers to the process of creating and sharing content on social media platforms in order to achieve marketing and branding goals.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-4 col-xl-3 wow fadeInUp" data-wow-delay="0.5s">
                            <div className="service-item rounded">
                                <div className="service-img rounded-top">
                                    <img src={ContentMarketing} className="img-fluid rounded-top w-100" alt="" />
                                </div>
                                <div className="service-content rounded-bottom bg-light p-4">
                                    <div className="service-content-inner">
                                        <h5 className="mb-4">CONTENT MARKETING</h5>
                                        <p className="mb-4">Is a strategic marketing approach focused on creating and distributing valuable, relevant, and consistent content to attract and retain a clearly defined audience.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-4 col-xl-3 wow fadeInUp" data-wow-delay="0.7s">
                            <div className="service-item rounded">
                                <div className="service-img rounded-top">
                                    <img src={WhatsApp} className="img-fluid rounded-top w-100" alt="" />
                                </div>
                                <div className="service-content rounded-bottom bg-light p-4">
                                    <div className="service-content-inner">
                                        <h5 className="mb-4">WHATSAPP MARKETING</h5>
                                        <p className="mb-4">Is a type of messenger marketing, which implies promoting a brand through WhatsApp. This platform helps brands reach an enormous audience, build strong relationships with customers.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-4 col-xl-3 wow fadeInUp" data-wow-delay="0.1s">
                            <div className="service-item rounded">
                                <div className="service-img rounded-top">
                                    <img src={SocialMediaHandling} className="img-fluid rounded-top w-100" alt="" />
                                </div>
                                <div className="service-content rounded-bottom bg-light p-4">
                                    <div className="service-content-inner">
                                        <h5 className="mb-4">SOCIAL MEDIA HANDLING</h5>
                                        <p className="mb-4">Refers to the active management and oversight of an organization's social media presence. It involves curating and creating content, engaging with followers, analyzing performance metrics.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-4 col-xl-3 wow fadeInUp" data-wow-delay="0.3s">
                            <div className="service-item rounded">
                                <div className="service-img rounded-top">
                                    <img src={InfluencerPromotion} className="img-fluid rounded-top w-100" alt="" />
                                </div>
                                <div className="service-content rounded-bottom bg-light p-4">
                                    <div className="service-content-inner">
                                        <h5 className="mb-4">INFLUENCER PROMOTION</h5>
                                        <p className="mb-4">It refers to a brand collaborating with an online influencer to market one of its products or services, This is because influencer marketing tends to be more authentic.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-4 col-xl-3 wow fadeInUp" data-wow-delay="0.5s">
                            <div className="service-item rounded">
                                <div className="service-img rounded-top">
                                    <img src={PagePromotion} className="img-fluid rounded-top w-100" alt="" />
                                </div>
                                <div className="service-content rounded-bottom bg-light p-4">
                                    <div className="service-content-inner">
                                        <h5 className="mb-4">PAGE PROMOTION</h5>
                                        <p className="mb-4">It is a Facebook marketing strategy that will help you to enhance your page visibility and brand awareness on Facebook.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Services;
