import React from 'react'
import creative from '../../img/creative.png'
import collob from '../../img/collob.png'
import expert from '../../img/expert.png'
import roll from '../../img/roll.png'

const ChooseSection = () => {
    return (

        <div class="container-fluid feature py-5">
            <div class="container py-5">
                <div class="section-title mb-5 wow fadeInUp" data-wow-delay="0.1s">
                    <div class="sub-style">
                        <h4 class="sub-title px-3 mb-0">Why Choose Us</h4>
                    </div>
                    <h1 class="display-3 mb-4" >Why Choose Us? Get Your Life Style Back</h1>
                    <p class="mb-0">With our website creation service, we craft tailored online platforms that captivate, engage, and convert.Whether you're a budding startup or an established enterprise, we're here to elevate your online presence and propel your business to new heights.</p>
                </div>
                <div class="row g-4 justify-content-center">
                    <div class="col-md-6 col-lg-4 col-xl-3 wow fadeInUp" data-wow-delay="0.1s">
                        <div class="row-cols-1 feature-item p-4">
                            <div class="col-12">
                                <div class="feature-icon mb-4">
                                    <div class="p-3 d-inline-flex bg-white rounded">
                                    <img 
  src={creative}
  style={{ 
    width: '80px', 
    height: '64px'
  }} 
  alt="Creative"
/>

                                    </div>
                                </div>
                                <div class="feature-content d-flex flex-column">
                                    <h5 class="mb-4">CUSTOMIZED SOLUTIONS</h5>
                                    <p class="mb-0">At Customized Solution, we understand that managing and ever changing IT world isn't easy while focusing on your core business.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6 col-lg-4 col-xl-3 wow fadeInUp" data-wow-delay="0.3s">
                        <div class="row-cols-1 feature-item p-4">
                            <div class="col-12">
                                <div class="feature-icon mb-4">
                                    <div class="p-3 d-inline-flex bg-white rounded">
                                        <img
                                            src={collob}
                                            style={{
                                                width: '80px',
                                                height: '64px',
                                                filter: 'hue-rotate(180deg)' // Example color filter
                                            }}
                                            alt="Collob"
                                        />

                                    </div>
                                </div>
                                <div class="feature-content d-flex flex-column">
                                    <h5 class="mb-4">CREATIVE SOLUTION</h5><br></br>
                                    <p class="mb-0">Creative thinking enables you to challenge current ideas and understand the urgency, relevance, and purpose of new solutions</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6 col-lg-4 col-xl-3 wow fadeInUp" data-wow-delay="0.5s">
                        <div class="row-cols-1 feature-item p-4">
                            <div class="col-12">
                                <div class="feature-icon mb-4">
                                    <div class="p-3 d-inline-flex bg-white rounded">
                                    <img 
  src={expert} 
  style={{ 
    width: '80px', 
    height: '64px'
  }} 
  alt="Expert"
/>

                                    </div>
                                </div>
                                <div class="feature-content d-flex flex-column">
                                    <h5 class="mb-4">EXPERT TEAMS</h5><br></br>
                                    <p class="mb-0">The idea of an expert team is that, first the skills or expertise of each individual team member,maintain technology product.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6 col-lg-4 col-xl-3 wow fadeInUp" data-wow-delay="0.7s">
                        <div class="row-cols-1 feature-item p-4">
                            <div class="col-12">
                                <div class="feature-icon mb-4">
                                    <div class="p-3 d-inline-flex bg-white rounded">
                                        <img src={roll} style={{ width: '80px', height: '64px' }} alt="Roll" />


                                    </div>
                                </div>
                                <div class="feature-content d-flex flex-column">
                                    <h5 class="mb-4">ROL-DRIVEN APPROACH</h5>
                                    <p class="mb-0">ROI used to evaluate the efficiency of an organization's learning investments,access control mechanism defined around.</p>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    )
}

export default ChooseSection