import React from 'react'
import About1 from '../../img/About1.jpeg'



const About = () => {
  return (
    <div class="container-fluid about bg-light py-5">
            <div class="container py-5">
                <div class="row g-5 align-items-center">
                    <div class="col-lg-5 wow fadeInLeft" data-wow-delay="0.2s">
                        <div class="about-img pb-5 ps-5">
                            <img src={About1} class="img-fluid rounded w-100" style={{ objectFit: 'cover' }} alt="" />
                           

                            
                        </div>
                    </div>
                    <div class="col-lg-7 wow fadeInRight" data-wow-delay="0.4s">
                        <div class="section-title text-start mb-5">
                            <h4 class="sub-title pe-3 mb-0">About Us</h4>
                            <h1 class="display-3 mb-4">With a focus on innovation & creativity</h1>
                            <p class="mb-4" style={{color: '#8d8d8d', fontSize:'18px'}}>Welcome to Corpwings, where we transform your digital dreams into reality. With our website creation service, we craft tailored online platforms that captivate, engage, and convert. Our team of seasoned designers and developers meticulously curate every aspect of your website, ensuring seamless functionality, stunning aesthetics, and user-friendly navigation.</p>
                            <div class="mb-4">
                                <p class="text-secondary"><i class="fa fa-check text-primary me-2"></i>Quality of content.</p>
                                <p class="text-secondary"><i class="fa fa-check text-primary me-2"></i> Level of user engagement.</p>
                                <p class="text-secondary"><i class="fa fa-check text-primary me-2"></i>Mobile-friendliness & Number and quality of inbound links</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
  )
}

export default About