import '../../css/style.css';
import './Intenship.css';

import software1 from '../../img/software1.jpg';
import digital2 from '../../img/digital2.jpg';
import grapic from '../../img/grapic.jpg';
import ADS from '../../img/ADS.jpg';
import AI from '../../img/AI.jpg';
import selfDelopment from '../../img/selfDelopment.jpg';
import itsector from '../../img/itsector.jpg';
import PP from '../../img/PP.jpg';
import sharemark from '../../img/sharemark.jpg';
import cyber from '../../img/cyber.jpg';

const services = [
    { imgSrc: software1, title: 'SOFTWARE DEVELOPING' },
    { imgSrc: digital2, title: 'DIGITAL MARKETING' },
    { imgSrc: grapic, title: 'GRAPHIC DESIGNING' },
    { imgSrc: AI, title: 'ARTIFICIAL INTELLIGENCE' },
    { imgSrc: ADS, title: 'ADMINISTRATIVE TECHNICAL SUPPORT' },
    { imgSrc: cyber, title: 'CYBER SECURITY' },
    { imgSrc: selfDelopment, title: 'SELF DEVELOPMENT' },
    { imgSrc: itsector, title: 'FUNDAMENTALS OF IT SECTOR' },
    { imgSrc: PP, title: 'PROJECT PREPARATION' },
    { imgSrc: sharemark, title: 'INVESTMENT & SHARE MARKETING' },
];

const Intenship = () => {
    return (
        <div>
            <div className="container-fluid service intenship py-5">
                <div className="container py-5">
                    <div className="section-title mb-5 wow fadeInUp" data-wow-delay="0.2s">
                        <div className="sub-style">
                            <h4 className="sub-title px-3 mb-0">What We Do</h4>
                        </div>
                        <h1 className="display-3 mb-4">OUR INTERNSHIP SERVICES</h1>
                    </div>

                    <div className="row g-4 justify-content-center">
                        {services.map((service, index) => (
                            <div key={index} className="col-md-6 col-lg-4 col-xl-3 wow fadeInUp" data-wow-delay={`${0.1 * (index + 1)}s`}>
                                <div className="service-item rounded">
                                    <div className="service-img rounded-top">
                                        <img src={service.imgSrc} className="img-fluid rounded-top w-100" alt={`internship ${index}`} />
                                    </div>
                                    <div className="service-content rounded-bottom bg-light p-4">
                                        <div className="service-content-inner">
                                            <h5 className="mb-4">{service.title}</h5>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
            <a className="btn btn-primary rounded-pill text-white py-2 px-4" href='#appointment' style={{ marginLeft: "650px", marginTop: "-70px" }}>Enroll</a>
        </div>
    );
};

export default Intenship;
