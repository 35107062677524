import React from 'react';
import Slider from 'react-slick';
import Homeimage1 from '../../img/Homeimage1.jpg';
import Homeimage2 from '../../img/Homeimage2.jpg';
import './HomeImgScroll.css'; // Import the CSS file for styling

// Settings for the carousel
const settings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 3000,
  swipe: false,
  draggable: false,
};

const HomeImgScroll = () => {
  return (
    <div className="home-img-scroll">
      <Slider {...settings}>
        <div className="carousel-item">
          <div className="image-container">
            <img src={Homeimage1} alt="Research and Marketing Solution" />
          </div>
          <div className="text-container ">
          <h1>SERVICE <br></br>&<br></br> CONSULTANCY</h1>
            {/* <h1>Research and Marketing Solution</h1>
            <h6>Innovative technologies or methodologies</h6> */}

            <a href="#appointment">Enroll Now</a>
          </div>
        </div>
        <div className="carousel-item">
          <div className="image-container">
            <img src={Homeimage2} alt="Marketing Solutions Overview" />
          </div>
          <div className="text-container ">
          <h1>SERVICE <br></br>&<br></br> CONSULTANCY</h1>
            {/* <h1>Research and Marketing Solution</h1>
            <h6>Innovative technologies or methodologies</h6> */}
            <a href="#appointment">Enroll Now</a>
          </div>
        </div>
      </Slider>
    </div>
  );
};

export default HomeImgScroll;
